@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.MuiListItemText-primary,
.p-badge,
.p-inputtext {
  font-family: "Montserrat", sans-serif !important;
}

body {
  font: 400 12px "Montserrat", sans-serif;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
}

input,
button,
textarea {
  font: 400 16px "Montserrat", sans-serif;
}

button {
  cursor: pointer;
}
form input {
  height: 50px;
  color: #333;
  border: 1px solid #dcdce6;
  border-radius: 8px;
  padding: 0 16px;
  width: 100%;
}

.input-class {
  height: 50px;
  color: #333;
  border: 1px solid #dcdce6;
  border-radius: 8px;
  padding: 0 16px;
  width: 100%;
}

.textarea-class {
  width: 100%;
  max-width: 350px;
  min-width: 60px;
  height: 60px;
  color: #333;
  border: 1px solid #dcdce6;
  border-radius: 8px;
  padding: 16px 24px;
  line-height: 24px;

  resize: vertical;
}

form textarea {
  width: 100%;
  max-width: 350px;
  min-width: 60px;
  height: 60px;
  color: #333;
  border: 1px solid #dcdce6;
  border-radius: 8px;
  padding: 16px 24px;
  line-height: 24px;

  resize: vertical;
}

form select {
  width: 100%;
  max-width: 350px;
  height: 60px;
  color: #333;
  border: 1px solid #dcdce6;
  border-radius: 8px;
  padding: 16px 24px;
  line-height: 24px;
}

.button {
  width: 100%;
  height: 46px;
  background-color: #ffb922;
  border: 0;
  border-radius: 8px;
  color: #fff;
  font-weight: 700;
  margin-top: 16px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  transition: all 0.2s;
}

.alter {
  color: rgba(0, 0, 0, 0.7);
  transition: all 0.2s;
  text-decoration: none;
}

.alter:hover {
  color: #ffb922;
}

.bg-color-primary {
  background: #f3c843;
}

.datatable-responsive-demo
  .p-datatable-responsive-demo
  .p-datatable-tbody
  > tr
  > td
  .p-column-title {
  display: none;
}

@media screen and (max-width: 40em) {
  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-thead
    > tr
    > th,
  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-tfoot
    > tr
    > td {
    display: none !important;
  }

  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td {
    text-align: left;
    display: block;
    width: 100%;
    float: left;
    clear: left;
    border: 0 none;
  }

  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td
    .p-column-title {
    padding: 0.4rem;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4rem;
    font-weight: bold;
  }

  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td:last-child {
    border-bottom: 1px solid var(--surface-d);
  }
}

.MuiAppBar-root {
  z-index: 1000 !important;
}

.map {
  height: 900px;
  width: 100%;
}

.map-marker {
  margin-top: -30px;
}
